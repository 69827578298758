import { format } from 'date-fns';
import type {
  Routine,
  RoutinePhase,
  RoutinePhaseExercise,
} from '@/types/extended';
import type {
  RoutineExerciseSet,
  RoutineAttributes,
  RoutinePhaseAttributes,
  RoutinePhaseExerciseAttributes,
  RoutineExerciseSetAttributes,
} from '@/types';

export function useTransformRoutineToAttributes() {
  function transformRoutineExerciseSet(set: RoutineExerciseSet): RoutineExerciseSetAttributes {
    return {
      id: set.id,
      comment: set.comment,
      setNumber: set.setNumber,
      repetitions: set.repetitions ?? undefined,
      weight: set.weight ?? undefined,
    };
  }

  function transformRoutinePhaseExercise(exercise: RoutinePhaseExercise): RoutinePhaseExerciseAttributes {
    const result: RoutinePhaseExerciseAttributes = {
      id: exercise.id,
      exerciseId: exercise.exerciseId,
      position: exercise.position,
      kind: exercise.kind,
      rest: exercise.restInSeconds,
      duration: exercise.durationInSeconds,
    };

    if (Array.isArray(exercise.routineExerciseSets)) {
      result.routineExerciseSetsAttributes = exercise.routineExerciseSets.map(transformRoutineExerciseSet);
    }

    return result;
  }

  function transformRoutinePhaseToAttributes(phase: RoutinePhase): RoutinePhaseAttributes {
    const result: RoutinePhaseAttributes = {
      id: phase.id,
      routineId: phase.routineId,
      name: phase.name,
      position: phase.position,
      sets: phase.sets,
    };

    if (Array.isArray(phase.routinePhaseExercises)) {
      result.routinePhaseExercisesAttributes = phase.routinePhaseExercises.map(transformRoutinePhaseExercise);
    }

    return result;
  }

  function transformRoutineToAttributes(routine: Routine): RoutineAttributes {
    const result: RoutineAttributes = {
      id: routine.id,
      name: routine.name,
      published: routine.isPublished,
      scheduledAt: format(new Date(routine.scheduledAt), "yyyy-MM-dd'T'HH:mm"),
      workoutPlanId: routine.workoutPlanId,
      duration: routine.durationInMinutes,
      noteForTrainer: routine.noteForTrainer,
      noteForTrainee: routine.noteForTrainee,
    };

    if (Array.isArray(routine.routinePhases)) {
      result.routinePhasesAttributes = routine.routinePhases.map(transformRoutinePhaseToAttributes);
    }

    return result;
  }

  return { transformRoutineToAttributes, transformRoutinePhaseToAttributes };
}
