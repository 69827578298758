<script setup lang="ts">
import { AxiosError } from 'axios';
import { computed, ref, toRef } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import type { Exercise, ExerciseAttributes } from '@/types/extended';
import { muscleGroupsApi, equipmentsApi, exercisesApi, movementPatternsApi } from '@/api';
import { BaseButton, BaseInput, BaseLabel, BaseSelect, BaseMultiselect } from '@/components';
import BackButton from './back-button.vue';

const props = defineProps<{
  exercise?: Exercise;
}>();

const isEditing = computed(() => props.exercise !== undefined);

const exercise = toRef(() => props.exercise);

const form = ref<Partial<ExerciseAttributes>>({
  name: exercise.value?.name ?? '',
  difficulty: exercise.value?.difficulty ?? 'beginner',
  kind: exercise.value?.kind ?? 'strength',
  movementPatternId: exercise.value?.movementPatternId ?? undefined,
  exerciseEquipmentAttributes: exercise.value?.exerciseEquipment ? {
    id: exercise.value.exerciseEquipment.id,
    equipmentId: exercise.value.exerciseEquipment.equipmentId,
  } : undefined,
  exerciseMuscleGroupsAttributes: exercise.value?.exerciseMuscleGroups?.map((exerciseMuscleGroup) => ({
    id: exerciseMuscleGroup.id,
    muscleGroupId: exerciseMuscleGroup.muscleGroupId,
  })) ?? [],
  exerciseInstructionsAttributes: exercise.value?.exerciseInstructions?.map((instruction) => ({
    id: instruction.id,
    description: instruction.description,
    position: instruction.position,
  })) ?? [],
  femalePoster: undefined,
  femaleVideo: undefined,
  malePoster: undefined,
  maleVideo: undefined,
});

const difficultyOptions = [
  { id: 'beginner', name: 'Principiante' },
  { id: 'intermediate', name: 'Intermedio' },
  { id: 'advanced', name: 'Avanzado' },
];

const kindOptions = [
  { id: 'strength', name: 'Fuerza' },
  { id: 'cardio', name: 'Cardio' },
  { id: 'stretching', name: 'Elongación' },
  { id: 'mobility', name: 'Movilidad' },
];

const { data: muscleGroupOptions } = useQuery({
  queryKey: ['muscleGroups'],
  queryFn: () => muscleGroupsApi.getAll(),
  initialData: [],
});

const selectedMuscleGroups = computed(
  () => form.value.exerciseMuscleGroupsAttributes!.map((muscleGroup) => muscleGroup.muscleGroupId));

function onMuscleGroupsChange(muscleGroupIds: number[]) {
  form.value.exerciseMuscleGroupsAttributes = muscleGroupIds.map((muscleGroupId) => ({ muscleGroupId }));
}

const { data: equipmentOptions } = useQuery({
  queryKey: ['equipments'],
  queryFn: () => equipmentsApi.getAll(),
  initialData: [],
});

const selectedEquipment = computed(() => form.value.exerciseEquipmentAttributes?.equipmentId);

const { data: movementPatternOptions } = useQuery({
  queryKey: ['movementPatterns'],
  queryFn: () => movementPatternsApi.getAll(),
  initialData: [],
});

function onMovementPatternChange(movementPatternId: number) {
  form.value.movementPatternId = movementPatternId;
}

function onEquipmentChange(equipmentId: number) {
  form.value.exerciseEquipmentAttributes = { equipmentId };
}

function onExerciseInstructionAdd() {
  const newInstruction = { description: '', position: form.value.exerciseInstructionsAttributes!.length + 1 };
  form.value.exerciseInstructionsAttributes = [...form.value.exerciseInstructionsAttributes!, newInstruction];
}

function onFileChange(event: Event, fileType: 'femalePoster' | 'femaleVideo' | 'malePoster' | 'maleVideo') {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    form.value[fileType] = target.files[0];
  }
}

const {
  isError,
  isPending,
  isIdle,
  isSuccess,
  mutate: saveExercise,
  reset: resetSaveExercise,
  error: saveExerciseError,
} = useMutation({
  mutationFn: () => (props.exercise === undefined ?
    exercisesApi.create(form.value as ExerciseAttributes) :
    exercisesApi.update(props.exercise.id, form.value as ExerciseAttributes)),
});

function onSubmit() {
  saveExercise();
}

</script>

<template>
  <div class="flex flex-col gap-6">
    <BackButton href="/exercises" />
    <h1 class="text-2xl font-bold">
      {{ isEditing ? 'Editar' : 'Crear' }} ejercicio
    </h1>
    <form
      v-if="isIdle"
      class="flex flex-col gap-4"
      @submit.prevent="onSubmit"
    >
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Nombre"
          name="name"
          required
        />
        <BaseInput
          v-model="form.name"
          name="name"
          type="text"
          autocomplete="off"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Dificultad"
          name="difficulty"
          required
        />
        <BaseSelect
          v-model="form.difficulty"
          name="difficulty"
          :options="difficultyOptions"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Tipo"
          name="kind"
          required
        />
        <BaseSelect
          v-model="form.kind"
          name="kind"
          :options="kindOptions"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Grupos musculares"
          name="exerciseMuscleGroupsAttributes"
          required
        />
        <BaseMultiselect
          :model-value="selectedMuscleGroups"
          name="exerciseMuscleGroupsAttributes"
          :options="muscleGroupOptions"
          @update:model-value="onMuscleGroupsChange($event as number[])"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Equipamiento"
          name="exerciseEquipmentAttributes"
          required
        />
        <BaseSelect
          :model-value="selectedEquipment"
          name="exerciseEquipmentAttributes"
          :options="equipmentOptions"
          @update:model-value="onEquipmentChange($event as number)"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Patrón de movimiento"
          name="movementPatternAttributes"
          required
        />
        <BaseSelect
          :model-value="form.movementPatternId"
          name="movementPatternAttributes"
          :options="movementPatternOptions"
          @update:model-value="onMovementPatternChange($event as number)"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Poster mujer"
          name="femalePoster"
        />
        <div class="flex">
          <input
            type="file"
            accept="image/*"
            @change="(event) => onFileChange(event, 'femalePoster')"
          >
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Video mujer"
          name="femaleVideo"
        />
        <div class="flex">
          <input
            type="file"
            accept="video/*"
            @change="(event) => onFileChange(event, 'femaleVideo')"
          >
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Poster hombre"
          name="malePoster"
        />
        <div class="flex">
          <input
            type="file"
            accept="image/*"
            @change="(event) => onFileChange(event, 'malePoster')"
          >
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Video hombre"
          name="maleVideo"
        />
        <div class="flex">
          <input
            type="file"
            accept="video/*"
            @change="(event) => onFileChange(event, 'femaleVideo')"
          >
        </div>
      </div>
      <div class="mb-2 flex flex-col">
        <BaseLabel
          label="Instrucciones"
          name="exerciseInstructionsAttributes"
        />
        <div class="mb-2 flex flex-col gap-1">
          <div
            v-for="instruction in form.exerciseInstructionsAttributes"
            :key="instruction.position"
            class="flex w-full items-center gap-3"
          >
            <span class="w-4">
              {{ instruction.position }}.
            </span>
            <BaseInput
              v-model="instruction.description"
              :name="`exerciseInstructionsAttributes[${instruction.position}].description`"
              type="text"
              class="w-full"
            />
          </div>
        </div>
        <div class="flex">
          <BaseButton
            variant="secondary"
            @click.prevent="onExerciseInstructionAdd"
          >
            Agregar instrucción
          </BaseButton>
        </div>
      </div>
      <div class="flex justify-end">
        <BaseButton type="submit">
          {{ isEditing ? 'Actualizar' : 'Crear' }} ejercicio
        </BaseButton>
      </div>
    </form>
    <div v-if="isPending">
      <p>
        {{ isEditing ? 'Actualizando' : 'Creando' }} ejercicio...
      </p>
    </div>
    <div
      v-else-if="isError"
      class="flex flex-col gap-6"
    >
      <p>
        Error al guardar el ejercicio: {{ (saveExerciseError as AxiosError).response?.data }}
      </p>
      <BaseButton
        variant="secondary"
        @click="() => resetSaveExercise()"
      >
        Volver a intentar
      </BaseButton>
    </div>
    <div v-else-if="isSuccess">
      <p>
        Ejercicio {{ isEditing ? 'actualizado' : 'creado' }} con éxito
      </p>
    </div>
  </div>
</template>
